document.addEventListener('DOMContentLoaded', () => {
    /*const video = document.querySelector('.features video');
    const videoDuration = video.duration;

    window.addEventListener('scroll', () => {
        const scrollTop = window.scrollY;
        const docHeight = document.documentElement.scrollHeight - window.innerHeight;
        const scrollFraction = scrollTop / docHeight;
        const videoTime = scrollFraction * videoDuration;

        video.currentTime = videoTime;
    }); */

    jQuery('a.contact_us').on('click', function(e) {
        e.preventDefault();
        var data = {
            'action': 'loadContact'
        };
        let text = jQuery(this).text();
        jQuery(this).text('Loading...');
        jQuery('.loader').show();
        jQuery.post(site.ajax_url, data, function(response) {
            jQuery('#contactModal').addClass('visible');
            jQuery('#contactModal .modal-content').html(response);
            wpcf7.init(document.querySelector('#contactModal .wpcf7-form'));
            jQuery('.loader').hide();
            jQuery('a.contact_us').text(text);
        });
    })

    jQuery(document).on('click', '.modal .close', function() {
        jQuery('#contactModal').removeClass('visible');
    });
});